<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.evaName"
              placeholder="评测名称"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
        <a-button v-hasPermission="'cate:add'" type="primary" @click="add" style="margin-left:20px;" icon="plus">
          新增
        </a-button>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.status | dictName('validStatus', 'color')">{{
            record.status | dictName("validStatus")
          }}
        </a-tag>
      </template>
      <template slot="resultType" slot-scope="text, record">
        <a-tag :color="record.resultType | dictName('evaluationResultType', 'color')">{{
            record.resultType | dictName("evaluationResultType")
          }}
        </a-tag>
      </template>
      <template slot="resultDesc" slot-scope="text, record">
        <a-popover placement="topLeft">
          <template slot="content">
            <div class="pop-content" v-html="text">{{ text }}</div>
          </template>
          <p style="margin-bottom: 0;max-height:100px;overflow: auto" v-html="text">{{ text }}</p>
        </a-popover>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-dropdown>
          <a-menu slot="overlay">
            <a-menu-item key="modify" @click="modify($event, record)"
                         v-hasPermission="'cate:edit'">
              <a-icon type="setting"/>
              修改
            </a-menu-item>
            <a-menu-divider/>
            <a-menu-item
                key="delete"
                v-hasPermission="'cate:delete'"
                style="color: red"
                @click="getDelete($event, record)"
            >
              <a-icon type="delete"/>
              删除
            </a-menu-item>
          </a-menu>
          <a-button>
            操作
            <a-icon type="down"/>
          </a-button>
        </a-dropdown>
      </template>
    </a-table>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";

export default {
  name: "EvaluationList",
  mixins: [ListMixin],
  data() {
    return {
      listGetUrl: "evaluation/recordList",
      listMethod: "get",
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order
        },
        {
          title: "用户名称",
          align: "center",
          width: 100,
          dataIndex: "userInfo.username",
        },

        {
          title: "评测名称",
          align: "center",
          width: 200,
          dataIndex: "evaName",
        },
        // {
        //   title: "状态",
        //   align: "center",
        //   width: 100,
        //   dataIndex: "status",
        // },
        {
          title: "评测时间",
          align: "center",
          width: 150,
          dataIndex: "startTime"
        },
        {
          title: "得分情况",
          width: 200,
          ellipsis: true,
          dataIndex: "score"
        },
        {
          title: "评测结果",
          width: 500,
          ellipsis: true,
          dataIndex: "resultDesc",
          scopedSlots: {customRender: "resultDesc"},
        },
        // {
        //   title: "操作",
        //   width: 120,
        //   align: "center",
        //   dataIndex: "operation",
        //   scopedSlots: {customRender: "operation"},
        // },
        {}
      ];
    },
  },

  mounted() {
    this.search(1);
  },
  methods: {
    editSuccess() {
      this.search(1);
    },
    modify(e, record) {
      this.$refs.modifyModal.showModal(record, 'edit');
    },
    add() {
      this.$refs.modifyModal.showModal(null, 'new');
    },
    getDelete(e, record) {
      let that = this;

      this.$confirm({
        title: "删除确认",
        content: "您确定要删除【" + record.evaName + "】分类吗？！",
        centered: true,
        onOk() {
          that.$post("evaluation/deleteConfig", {evaId: record.id}).then(() => {
            that.$message.success("删除成功");
            that.search();
          });
        },
      });
    },
  },
};
</script>

<style>
.factor-title {
  background-color: #00B578;
  color: #FFF;
  padding: 5px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: 10px 0;
}

.sec-title {
  font-size: 1.1em;
  margin: 15px 0 10px;
}
</style>
<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}

.pop-content {
  max-width: 800px;

  p {
    text-overflow: inherit !important;
    overflow: auto !important;
    white-space: inherit !important;
  }
}
</style>
